import type { FC } from 'react';
import React from 'react';

import { Icon, LazyLoaded } from '@/components';
import womenPng from '@/public/assets/home/women.png';
import womenWebp from '@/public/assets/home/women.webp';
import women2xPng from '@/public/assets/home/women@2x.png';
import women2xWebp from '@/public/assets/home/women@2x.webp';
import { cn } from '@/utils';

import { Container } from '../Container';

interface HeroDepositsProps {
  h1: string;
  advantages: string[];
}
export const HeroDeposits: FC<HeroDepositsProps> = ({ h1, advantages }) => {
  return (
    <Container className="relative overflow-hidden md:pb-[76px] md:pt-[78px] lg:pb-[144px] lg:pt-[98px] xl:pb-[130px] xl:pt-[90px]">
      <div className="mb-8 flex flex-col items-center justify-between pb-2 pt-9 sm:mb-12 sm:pb-8 md:mb-0 md:flex-row md:px-[2px] md:py-0 lg:max-w-[532px] lg:px-0 xl:max-w-[679px]">
        <div className="z-10 flex w-full flex-col md:w-2/3 lg:w-full">
          <h1 className="md:h2 mb-[17px] mt-0.5 text-[32px] font-bold leading-[40px] text-blue-900 md:text-[40px] md:leading-[48px] md:tracking-[-0.6px] lg:mb-[15px] lg:ml-0.5">
            {h1}
          </h1>
          {advantages.map((advantage, index) => (
            <p
              key={index}
              className="body3 sm:body2 mb-3 flex max-w-[65%] items-start gap-2 tracking-[0.2px] text-grey-800 last:mb-[56px] sm:max-w-[unset] md:items-center md:last:mb-0"
            >
              <Icon
                name="check"
                className="ml-0.5 mt-1 shrink-0 stroke-transparent text-green-500 md:mt-0"
              />
              {advantage}
            </p>
          ))}
        </div>
      </div>
      <div className="pointer-events-none absolute left-[85%] top-24 flex h-[274px] w-[417px] items-center sm:left-[50%] sm:top-10 sm:h-[500px] sm:w-[716px] md:left-[60%] md:top-10 lg:left-[60%] xl:left-[68%]">
        <div className="shadow-image h-[30px] w-[20px] rounded-full bg-white sm:h-[200px] sm:w-[100px] md:h-[360px] md:w-[150px]" />
      </div>
      <LazyLoaded>
        <div
          className={cn(
            'absolute bottom-1 sm:bottom-[0] right-[-120px] z-[5] h-[251px] w-[446px] scale-x-[-1] min-[530px]:right-0 md:left-[250px] md:max-h-[430px] md:min-h-[430px] md:min-w-[620px] md:max-w-[620px] md:scale-x-[1] lg:left-[350px] lg:min-h-[436px] lg:min-w-[670px] xl:left-[50%]',
          )}
        >
          <picture className="flex h-full w-[283px] text-transparent md:w-full">
            <source
              srcSet={`${womenWebp.src} 1x, ${women2xWebp.src} 2x`}
              type="image/webp"
            />
            <source
              srcSet={`${womenPng} 1x, ${women2xPng} 2x`}
              type="image/png"
              media="(max-width: 320px)"
            />
            <img
              className="m-auto"
              src={womenPng.src}
              alt="women"
              width={womenPng.width}
              height={womenPng.height}
            />
          </picture>
        </div>
      </LazyLoaded>
    </Container>
  );
};
